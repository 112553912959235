import { useScript } from '~/utils/use-script';

export const Gtm: React.FC = () => {
  useScript({
    inlineScriptContent: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-TLQ2G5CC');`,
  });

  return (
    <noscript>
      <iframe
        title="gtm"
        src="https://www.googletagmanager.com/ns.html?id=GTM-TLQ2G5CC"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};
