import { useEffect } from 'react';

export const useScript = ({
  url,
  inlineScriptContent,
}: {
  url?: string;
  inlineScriptContent?: string;
}) => {
  useEffect(() => {
    if (!url && !inlineScriptContent) {
      return;
    }

    const script = document.createElement('script');

    if (url) {
      script.src = url;
      script.async = true;
      script.crossOrigin = 'true';
      script.type = 'module';
    } else if (inlineScriptContent) {
      script.innerHTML = inlineScriptContent;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, inlineScriptContent]);
};
