import { Card, Container, Group, SimpleGrid, Text, Title } from '@mantine/core';
import {
  IconCloudLock,
  IconCreditCard,
  IconDatabase,
  IconKey,
  IconLockBolt,
  IconShieldCheck,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { AppLink } from '../Shared/AppLink';
import { AppIcon } from '../Shared/Icon/AppIcon';

const securityData = [
  {
    title: 'ISO 27001 Certification',
    description:
      'Our platform is certified under ISO 27001:2013, an internationally recognized standard for information security management. This ensures we have the necessary processes in place to protect your data from unauthorized access and vulnerabilities.',
    icon: IconLockBolt,
    link: 'https://vercel.com/changelog/vercel-has-now-achieved-the-iso-27001-2013-certification',
  },
  {
    title: 'SOC 2 Type 2 Compliance',
    description:
      'Our services adhere to SOC 2 Type 2 compliance, covering security, availability, and confidentiality. This framework ensures your data remains secure and private, with regular audits confirming our commitment to these standards.',
    icon: IconShieldCheck,
    link: 'https://vercel.com/security',
  },
  {
    title: 'Protection Against Data Breaches',
    description:
      'We implement robust data encryption, both in transit and at rest, to protect against potential breaches. Additionally, we maintain GDPR compliance, ensuring any personal data is handled with the utmost care and transparency.',
    icon: IconCloudLock,
    link: 'https://vercel.com/security',
  },
  {
    title: 'Secure User Authentication',
    description:
      'Clerk’s authentication system ensures that user data is securely handled with multi-factor authentication (MFA) and encryption. This guarantees that only authorized users have access to sensitive information, safeguarding against unauthorized access.',
    icon: IconKey,
    link: 'https://clerk.com/docs/security/overview',
  },
  {
    title: 'PCI DSS Compliance',
    description:
      'For customers handling payment data, our infrastructure supports PCI DSS compliance, ensuring that all credit card transactions are securely processed according to the latest security standards.',
    icon: IconCreditCard,
    link: 'https://vercel.com/security',
  },
  {
    title: 'Database Security',
    description:
      "Our infrastructure is hosted in Amazon's secure data centers, backed by AWS Cloud Security. Data-at-rest is encrypted using XTS-AES-256 on NVMe SSD volumes, ensuring compliance with industry standards and protecting against unauthorized access.",
    icon: IconDatabase,
    link: 'https://neon.tech/docs/security/security-overview',
  },
] as const;

export const HomeSecurity: React.FC = () => {
  const { t } = useTranslation('homePage');

  const security = securityData.map((secure) => (
    <Card key={secure.title} shadow="md" radius="md" padding="xl">
      <Group align="center">
        <AppIcon Icon={secure.icon} size={'2.5rem'} />
        <Text fz="lg" fw={500}>
          {t(secure.title)}
        </Text>
      </Group>
      <Text fz="sm" c="dimmed" mt="sm">
        {t(secure.description)}
      </Text>
      <AppLink href={secure.link} mt="md" newTab variant="gradient">
        {t('Read more')}
      </AppLink>
    </Card>
  ));

  return (
    <Container pt={'min(8vw, 80px)'} pb={'min(8vw, 80px)'} size={1400}>
      <Title order={2} ta="center" mt="sm" id="security">
        {t('Security and Compliance')}
      </Title>

      <Text ta="center" mt="md">
        {t(
          'Our platform ensures the highest standards of security and compliance, empowering your business to grow confidently while protecting customer data and meeting industry regulations.',
        )}
      </Text>

      <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
        {security}
      </SimpleGrid>
    </Container>
  );
};
