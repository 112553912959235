import '@mantine/carousel/styles.css';
import { Carousel } from '@mantine/carousel';
import { Box, Stack, Text, Title, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import Autoplay from 'embla-carousel-autoplay';
import Image, { type StaticImageData } from 'next/image';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '~/ui/hooks/locale.hook';
import { useColorBySchema } from '~/ui/hooks/mantine-color.hook';
import { useAppMediaQuery } from '~/ui/hooks/media-query.hook';
import { AppIcon } from '../Shared/Icon/AppIcon';
import { MarketingButton } from './MarketingButton';

type ImageImport = () => Promise<{ default: StaticImageData }>;

const heLightCarouselImages: { title: string; image: ImageImport }[] = [
  { title: 'Main Table', image: () => import('../../../assets/home-screen/contact-he-light.png') },
  { title: 'Agenda', image: () => import('../../../assets/home-screen/agenda-he-light.png') },
  { title: 'Contact', image: () => import('../../../assets/home-screen/contact-he-light.png') },
  { title: 'Dashboard', image: () => import('../../../assets/home-screen/dashboard-he-light.png') },
  { title: 'Tasks', image: () => import('../../../assets/home-screen/tasks-he-light.png') },
  { title: 'Kanban', image: () => import('../../../assets/home-screen/kanban-he-light.png') },
  { title: 'Automation', image: () => import('../../../assets/home-screen/automation-he-light.png') },
  { title: 'Finance', image: () => import('../../../assets/home-screen/finance-he-light.png') },
];

const heDarkCarouselImages: { title: string; image: ImageImport }[] = [
  { title: 'Main Table', image: () => import('../../../assets/home-screen/contact-he-dark.png') },
  { title: 'Agenda', image: () => import('../../../assets/home-screen/agenda-he-dark.png') },
  { title: 'Contact', image: () => import('../../../assets/home-screen/contact-he-dark.png') },
  { title: 'Dashboard', image: () => import('../../../assets/home-screen/dashboard-he-dark.png') },
  { title: 'Tasks', image: () => import('../../../assets/home-screen/tasks-he-dark.png') },
  { title: 'Kanban', image: () => import('../../../assets/home-screen/kanban-he-dark.png') },
  { title: 'Automation', image: () => import('../../../assets/home-screen/automation-he-dark.png') },
  { title: 'Finance', image: () => import('../../../assets/home-screen/finance-he-dark.png') },
];

const enLightCarouselImages: { title: string; image: ImageImport }[] = [
  { title: 'Main Table', image: () => import('../../../assets/home-screen/contact-en-light.png') },
  { title: 'Agenda', image: () => import('../../../assets/home-screen/agenda-en-light.png') },
  { title: 'Contact', image: () => import('../../../assets/home-screen/contact-en-light.png') },
  { title: 'Dashboard', image: () => import('../../../assets/home-screen/dashboard-en-light.png') },
  { title: 'Tasks', image: () => import('../../../assets/home-screen/tasks-en-light.png') },
  { title: 'Kanban', image: () => import('../../../assets/home-screen/kanban-en-light.png') },
  { title: 'Automation', image: () => import('../../../assets/home-screen/automation-en-light.png') },
  { title: 'Finance', image: () => import('../../../assets/home-screen/finance-en-light.png') },
];

const enDarkCarouselImages: { title: string; image: ImageImport }[] = [
  { title: 'Main Table', image: () => import('../../../assets/home-screen/contact-en-dark.png') },
  { title: 'Agenda', image: () => import('../../../assets/home-screen/agenda-en-dark.png') },
  { title: 'Contact', image: () => import('../../../assets/home-screen/contact-en-dark.png') },
  { title: 'Dashboard', image: () => import('../../../assets/home-screen/dashboard-en-dark.png') },
  { title: 'Tasks', image: () => import('../../../assets/home-screen/tasks-en-dark.png') },
  { title: 'Kanban', image: () => import('../../../assets/home-screen/kanban-en-dark.png') },
  { title: 'Automation', image: () => import('../../../assets/home-screen/automation-en-dark.png') },
  { title: 'Finance', image: () => import('../../../assets/home-screen/finance-en-dark.png') },
];

const huLightCarouselImages: { title: string; image: ImageImport }[] = [
  { title: 'Main Table', image: () => import('../../../assets/home-screen/contact-hu-light.png') },
  { title: 'Agenda', image: () => import('../../../assets/home-screen/agenda-hu-light.png') },
  { title: 'Contact', image: () => import('../../../assets/home-screen/contact-hu-light.png') },
  { title: 'Dashboard', image: () => import('../../../assets/home-screen/dashboard-hu-light.png') },
  { title: 'Tasks', image: () => import('../../../assets/home-screen/tasks-hu-light.png') },
  { title: 'Kanban', image: () => import('../../../assets/home-screen/kanban-hu-light.png') },
  { title: 'Automation', image: () => import('../../../assets/home-screen/automation-hu-light.png') },
  { title: 'Finance', image: () => import('../../../assets/home-screen/finance-hu-light.png') },
];

const huDarkCarouselImages: { title: string; image: ImageImport }[] = [
  { title: 'Main Table', image: () => import('../../../assets/home-screen/contact-hu-dark.png') },
  { title: 'Agenda', image: () => import('../../../assets/home-screen/agenda-hu-dark.png') },
  { title: 'Contact', image: () => import('../../../assets/home-screen/contact-hu-dark.png') },
  { title: 'Dashboard', image: () => import('../../../assets/home-screen/dashboard-hu-dark.png') },
  { title: 'Tasks', image: () => import('../../../assets/home-screen/tasks-hu-dark.png') },
  { title: 'Kanban', image: () => import('../../../assets/home-screen/kanban-hu-dark.png') },
  { title: 'Automation', image: () => import('../../../assets/home-screen/automation-hu-dark.png') },
  { title: 'Finance', image: () => import('../../../assets/home-screen/finance-hu-dark.png') },
];

const imagesMap = {
  he: {
    light: heLightCarouselImages,
    dark: heDarkCarouselImages,
  },
  en: {
    light: enLightCarouselImages,
    dark: enDarkCarouselImages,
  },
  hu: {
    light: huLightCarouselImages,
    dark: huDarkCarouselImages,
  },
};

const SlideCard: React.FC<{ image: ImageImport; title: string }> = ({ image, title }) => {
  const [imgSrc, setImgSrc] = useState<StaticImageData | null>(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const imgModule = await image();
        setImgSrc(imgModule.default);
      } catch (error) {
        console.error('Failed to load image:', error);
      }
    };

    loadImage().catch(console.error);
  }, [image]);

  return (
    <Carousel.Slide>
      <Box display="flex" pos="relative" w="100%" maw={1060} m={'0 auto'}>
        {imgSrc && (
          <Image priority={false} src={imgSrc} alt={title} style={{ width: '100%', height: 'auto' }} />
        )}
      </Box>
    </Carousel.Slide>
  );
};

export const HomeDescription: React.FC = () => {
  const { t } = useTranslation('homePage');
  const { colorScheme } = useMantineColorScheme();
  const { locale } = useLocale();
  const isDark = colorScheme === 'dark';

  const images = useMemo(() => {
    return imagesMap[locale][isDark ? 'dark' : 'light'];
  }, [isDark, locale]);

  const bg = useColorBySchema('violet.0', 'dark');

  const { colors } = useMantineTheme();

  const isSmallScreen = useAppMediaQuery('lg');
  const { dir } = useLocale();

  const autoplay = useRef(Autoplay({ delay: 5000, active: true }));

  const handleMouseEnter = useCallback(() => autoplay.current.stop(), []);
  const handleMouseLeave = useCallback(() => autoplay.current.reset(), []);

  return (
    <Box w="100%" p={'2rem 0'} bg={bg}>
      <Stack align="center" px="xs" gap={'xl'}>
        <Title order={2} ta="center" mt="sm">
          {t(
            'CRM system for leads management, customer relations, accounting, marketing automation and more',
          )}
        </Title>
        <Text
          fw={400}
          style={{
            textAlign: 'center',
            fontSize: 'max(min(1.3vw,1.2rem), 1rem)',
          }}
        >
          {t(
            'Next Zebra is a powerful CRM solution designed to help businesses of all sizes effectively manage customers, streamline sales processes, and enhance customer service efficiency',
          )}
        </Text>
        <MarketingButton radius={'xl'} text={t('Open free trial account')} />
        <Box style={{ width: '100%', maxWidth: '1400px' }}>
          <Carousel
            draggable
            loop
            align="center"
            slideSize={isSmallScreen ? '100%' : '80%'}
            controlsOffset={isSmallScreen ? 0 : 'xl'}
            withIndicators={!isSmallScreen}
            h={'100%'}
            height={isSmallScreen ? 'auto' : 650}
            controlSize={isSmallScreen ? 40 : 60}
            slideGap={isSmallScreen ? 'xs' : 'sm'}
            nextControlIcon={
              <AppIcon
                Icon={dir === 'ltr' ? IconArrowRight : IconArrowLeft}
                color={'violet.8'}
                size={isSmallScreen ? 15 : 25}
              />
            }
            previousControlIcon={
              <AppIcon
                Icon={dir === 'ltr' ? IconArrowLeft : IconArrowRight}
                color={'violet.8'}
                size={isSmallScreen ? 15 : 25}
              />
            }
            plugins={[autoplay.current]}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            styles={{
              viewport: {
                overflowY: 'scroll',
              },
              indicator: {
                backgroundColor: isDark ? colors.violet[1] : colors.violet[9],
                width: 40,
                height: 7,
                transition: 'width 250ms ease',
              },
              slide: {
                transition: 'filter 800ms ease',
              },
              control: {
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
              },
            }}
          >
            {images.map((image) => (
              <SlideCard key={image.title} title={image.title} image={image.image} />
            ))}
          </Carousel>
        </Box>
      </Stack>
    </Box>
  );
};

export default HomeDescription;
