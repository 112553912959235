import { Card, Container, Group, SimpleGrid, Text, Title } from '@mantine/core';
import {
  IconBusinessplan,
  IconCheckbox,
  IconClock,
  IconColorFilter,
  IconDatabase,
  IconLayout,
  IconLayout2,
  IconMessage2,
  IconStarsFilled,
} from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppIcon } from '../Shared/Icon/AppIcon';

const featuresData = [
  {
    title: 'Easy to Fit Your Needs',
    description:
      'Effortlessly organize your system to suit your business needs, including managing clients, contacts, projects, and more. Easily generate and filter reports for comprehensive insights.',
    icon: IconLayout2,
  },
  {
    title: 'Automations',
    description: 'Streamline your workflows and save time with automated processes.',
    icon: IconClock,
  },
  {
    title: 'Task Management',
    description:
      'Efficiently manage tasks related to clients and projects etc., ensuring nothing falls through the cracks. Stay organized and on top of your workload with intuitive task management tools.',
    icon: IconCheckbox,
  },
  {
    title: 'Finance Documents',
    description: `Manage your organization's financial documents, as well as maintain a product catalog. This way, you can create invoices, receipts, and other financial documents according to your needs.`,
    icon: IconBusinessplan,
  },
  {
    title: 'User-Friendly Interface',
    description:
      'Enjoy a simple and intuitive user interface that makes navigation and operation a breeze. Whether on desktop or mobile, our responsive UI ensures a seamless experience for all users.',
    icon: IconLayout,
  },
  {
    title: 'Customizable Reports',
    description:
      'Generate and customize reports to gain insights into your business operations. Filter data to focus on specific metrics and make data-driven decisions for your business.',
    icon: IconColorFilter,
  },
  {
    title: 'Data Security',
    description:
      'Protect your data with advanced security features. Ensure that your business information is safe and secure with Next Zebra CRM.',
    icon: IconColorFilter,
  },
  {
    title: 'Storage & File Management',
    description:
      'Store and manage files related to your clients, projects, and business operations. Access files from anywhere, anytime, and share them with your team.',
    icon: IconDatabase,
  },
  {
    title: 'Emails, Messages and more',
    description:
      'Send emails, messages and more directly from Next Zebra CRM, or just record them. Communicate with your clients.',
    icon: IconMessage2,
  },
  {
    title: 'AI-powered assistant',
    description:
      'Automate customer service with an AI-powered assistant. Answer frequently asked questions based on your customer interactions and more.',
    icon: IconStarsFilled,
  },
] as const;

export const HomeFeatures: React.FC = () => {
  const { t } = useTranslation('homePage');

  const features = featuresData.map((feature) => (
    <Card key={feature.title} shadow="md" radius="md" padding="xl">
      <Group align="center">
        <AppIcon Icon={feature.icon} size={'2.5rem'} />
        <Text fz="lg" fw={500}>
          {t(feature.title)}
        </Text>
      </Group>
      <Text fz="sm" c="dimmed" mt="sm">
        {t(feature.description)}
      </Text>
    </Card>
  ));

  return (
    <Container pt={'min(8vw, 80px)'} pb={'min(8vw, 80px)'} size={1400}>
      <Title order={2} ta="center" mt="sm" id="core-features">
        {t('Our core features')}
      </Title>

      <Text ta="center" mt="md">
        {t(
          'Discover the power of Next Zebra CRM to elevate your sales strategy, enhance customer relationships, and drive sustainable growth for your business.',
        )}
      </Text>

      <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
        {features}
      </SimpleGrid>
    </Container>
  );
};
