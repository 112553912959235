import Image from 'next/image';
import homeBg1 from '../../../assets/home-bg-1.png';
import homeBg2 from '../../../assets/home-bg-2.png';
import homeBg3 from '../../../assets/home-bg-3.png';

export const BackgroundImages: React.FC = () => {
  return (
    <>
      <Image
        style={{
          position: 'absolute',
          left: 'auto',
          top: '0',
          right: '0',
          bottom: 'auto',
          zIndex: 1,
          opacity: '0.5',
          width: '50%',
          maxWidth: '50%',
          height: 'auto',
        }}
        loading="eager"
        priority
        alt="home-bg-1"
        src={homeBg1}
      />
      <Image
        style={{
          position: 'absolute',
          left: 'auto',
          top: '500px',
          right: '0',
          bottom: 'auto',
          zIndex: 1,
          opacity: '0.5',
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          height: 'auto',
        }}
        alt="home-bg-2"
        src={homeBg2}
      />
      <Image
        style={{
          position: 'absolute',
          left: 'auto',
          top: '650px',
          right: '0',
          bottom: 'auto',
          zIndex: 1,
          opacity: '0.5',
          width: '100%',
          maxWidth: '900px',
          margin: '0 auto',
          height: 'auto',
        }}
        alt="home-bg-3"
        src={homeBg3}
      />
    </>
  );
};
