import { useAuth } from '@clerk/nextjs';
import { Button, type ButtonProps, type DefaultMantineColor, Text } from '@mantine/core';
import NextLink from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const MarketingButton: React.FC<
  ButtonProps & { text: string; textColor?: DefaultMantineColor }
> = ({ text, textColor, ...props }) => {
  const { t } = useTranslation('headerComponent');
  const { isSignedIn } = useAuth();
  const href = isSignedIn ? '/cards' : '/signup';

  return (
    <NextLink href={href}>
      <Button
        size="lg"
        miw={215}
        style={{ boxShadow: '0px 2px 22px 9px var(--mantine-color-violet-light)' }}
        {...props}
      >
        <Text c={textColor} fw={700} fz={18}>
          {isSignedIn ? t('Back to App') : text}
        </Text>
      </Button>
    </NextLink>
  );
};
