import { Box, Container, Stack, Text, Title, useMantineColorScheme } from '@mantine/core';
import Image from 'next/image';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '~/ui/hooks/locale.hook';
import { useColorBySchema } from '~/ui/hooks/mantine-color.hook';
import kanbanEnDarkImage from '../../../assets/home-screen/kanban-en-dark.png';
import kanbanEnLightImage from '../../../assets/home-screen/kanban-en-light.png';
import kanbanHeDarkImage from '../../../assets/home-screen/kanban-he-dark.png';
import kanbanHeLightImage from '../../../assets/home-screen/kanban-he-light.png';
import kanbanHuDarkImage from '../../../assets/home-screen/kanban-hu-dark.png';
import kanbanHuLightImage from '../../../assets/home-screen/kanban-hu-light.png';
import { MarketingButton } from './MarketingButton';

const localeImages = {
  en: {
    dark: kanbanEnDarkImage,
    light: kanbanEnLightImage,
  },
  he: {
    dark: kanbanHeDarkImage,
    light: kanbanHeLightImage,
  },
  hu: {
    dark: kanbanHuDarkImage,
    light: kanbanHuLightImage,
  },
} as const;

export const HomeHero: React.FC = () => {
  const { t } = useTranslation('homePage');
  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === 'dark';
  const { locale } = useLocale();

  const selectedImage = useMemo(() => {
    return localeImages[locale][isDark ? 'dark' : 'light'];
  }, [locale, isDark]);

  const buttonColor = useColorBySchema('dark', 'gray.0');
  const buttonTextColor = useColorBySchema('white', 'dark');

  return (
    <Container display={'flex'} pt={'min(8vw, 60px)'} pb={'min(8vw, 80px)'} mih={'100vh'}>
      <Stack align="center" justify="space-between">
        <Title
          order={1}
          size={'3rem'}
          maw={'800px'}
          style={{
            textAlign: 'center',
          }}
        >
          {t('The next generation of CRM')}
        </Title>
        <Text
          fw={400}
          maw={700}
          style={{
            fontSize: 'max(min(1.3vw,1.2rem), 1rem)',
            textAlign: 'center',
          }}
        >
          {t(
            'Next Zebra CRM unifies your sales, marketing, and customer service teams onto one platform, empowering you to deliver unforgettable customer experiences at every touchpoint.',
          )}
        </Text>
        <Stack gap={'xs'}>
          <MarketingButton
            radius={'xl'}
            text={t('Open free trial account')}
            color={buttonColor}
            textColor={buttonTextColor}
          />
          <Text fz={'sm'} ta="center">
            {t('Credit card is not required')}
          </Text>
        </Stack>

        <Box display="flex" pos="relative" w="100%" maw={1600} m={'0 auto'}>
          <Image
            alt="Kanban board with tasks and notes next zebra crm"
            src={selectedImage}
            priority
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Text
          fw={400}
          maw={700}
          style={{
            fontSize: 'max(min(1.3vw,1.2rem), 1rem)',
            textAlign: 'center',
          }}
        >
          {t(
            'Streamline your business operations, optimize customer engagements, and boost ROI—all while prioritizing the needs of your customers at the center of every interaction.',
          )}
        </Text>
        <MarketingButton radius={'xl'} text={t('Try now! it’s free')} />
      </Stack>
    </Container>
  );
};
